<template>
  <div>
    <v-breadcrumbs :items="breadCrumbs" class="text-body-1 mt-4 pa-2">
      <template slot="item" slot-scope="props">
        <span
          :class="
            props.item.disabled
              ? 'dark-grey-text'
              : 'indigo--text text--accent-2 pointer'
          "
          @click="$router.push(props.item.to)"
          v-text="props.item.text"
        ></span>
      </template>
      <v-icon slot="divider">mdi-chevron-right</v-icon>
    </v-breadcrumbs>
    <v-row class="mt-12">
      <v-col cols="12">
        <div class="font-size-h3 bold-text">Connect Slack</div>
        <div class="text-muted">
          Connect with the slack workspace to get notifications about releases
          and updates.
        </div>
      </v-col>
      <v-col cols="12">
        <v-alert outlined color="primary">
          <span class="black--text">Get started: </span>
          <b class="cursor-pointer">Read more</b>
          <span class="black--text"> about slack integrations</span>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-card outlined class="pa-3">
          <v-row align="center">
            <v-col cols="auto">
              {{
                isSlackEnabled
                  ? "You have enabled slack connection"
                  : "Your team is not connected to slack"
              }}
            </v-col>
            <v-col cols="auto">
              <v-btn
                v-if="!isSlackEnabled"
                class="text-transform-none"
                @click="generateSlackAuthLink"
                depressed
                :disabled="loading"
                color="primary"
                >Enable</v-btn
              >
              <v-btn
                v-if="isSlackEnabled"
                class="text-transform-none"
                @click="disableSlackIntegration"
                depressed
                :disabled="loading"
                color="error"
                >Disable</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <Modal v-model="openSuccessPopup">
      <template #message>
        <v-row class="py-4">
          <v-col class="text-center text-h5" cols="12">
            Congratulations 🎉
          </v-col>
          <v-col class="text-center text-h6" cols="12">
            We are processing the integration <br />
            and connecting with your slack account.
          </v-col>
          <v-col class="text-center" cols="12">
            <v-progress-circular
              indeterminate
              color="success"
            ></v-progress-circular>
          </v-col>
          <v-col class="text-center" cols="12">
            It should be done within few moments.
          </v-col>
        </v-row>
      </template>
    </Modal>
  </div>
</template>

<script>
import { ENABLE_SLACK } from "@/store/team/integrations.module.js";
import { isEmpty } from "@/core/services/helper.service";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      breadCrumbs: [
        { text: "Team", disabled: false, to: { name: "integrations" } },
        {
          text: "Integrations",
          disabled: false,
          to: { name: "integrations" }
        },
        {
          text: "Slack",
          disabled: true
        }
      ],
      authDetails: {},
      openSuccessPopup: false,
      loading: false,
      slackEnabled: false
    };
  },
  computed: {
    ...mapGetters({
      getIntegrationList: "getIntegrationList"
    }),
    isSlackEnabled() {
      return this.getIntegrationList && this.getIntegrationList.length
        ? this.getIntegrationList[0].status
        : false;
    }
  },
  created() {
    this.extractDetail();
  },
  methods: {
    extractDetail() {
      if (
        this.$route.name === "slack-integration-auth" &&
        !isEmpty(this.$route.query)
      ) {
        const details = { ...this.$route.query };
        const hash = this.$route.hash;
        if (hash && hash.startsWith("#") && !details.channel) {
          let data = hash.split("&");
          details.channel = data[0];
          details.url = data[3] ? data[3].split("=")[1] : "";
          details.channel_id = data[1] ? data[1].split("=")[1] : "";
          details.configuration_url = data[2] ? data[2].split("=")[1] : "";
        }
        const dataToPost = {
          info: {
            token: details.token,
            incoming: {
              url: details.url || "",
              channel: details.channel || "",
              channel_id: details.channel_id || "",
              configuration_url: details.configuration_url || ""
            },
            team_id: details.team_id,
            team_name: details.team_name
          }
        };
        if (this.loading) return;
        this.loading = true;
        this.$store
          .dispatch(ENABLE_SLACK, dataToPost)
          .then(response => {
            this.openSuccessPopup = true;
            setTimeout(() => {
              this.openSuccessPopup = false;
              this.$router.push({name: 'integrations'})
              this.notifyUserMessage(response.message);
              this.loading = false;
            }, 4000);
          })
          .catch(err => {
            this.notifyErrorMessage(err.message);
          });
      } else {
        this.openSuccessPopup = true;
        setTimeout(() => {
          this.openSuccessPopup = false;
          this.$router.push({name: 'integrations'})
          this.loading = false;
        }, 4000);
      }
    }
  }
};
</script>

<style scoped></style>
